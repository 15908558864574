<!-- 竹藤展览 -->
<template>
  <div class="exhibition">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/exhibition/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title" :class="{ weibei: lang }">
              {{ $t("bambooExhibition") }}
            </div>
          </div>
          <div class="header h5" :class="{ weibei: lang }">
            <img
              src="@/assets/images/exhibition/banner-h5.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title">{{ $t("bambooExhibition") }}</div>
          </div>
          <div class="lcontainer container" v-if="0">
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("introduction") }}
              </div>
              <p>{{ $t("bambooExhibitionP1") }}</p>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP2") }}
              </div>
              <div class="tab-box">
                <!-- <div class="m-b-24 g-color" style="font-size:20px;font-weight: 700;">时间表</div> -->
                <!-- <el-image style="width: 100%" :src="tableImg" :fit="'fill'">
                                </el-image> -->
                <table border="1" cellspacing="0" class="exhTable">
                  <tbody>
                    <tr
                      style="
                        background: #8dc32b;
                        color: #fff;
                        height: 60px;
                        font-weight: 700;
                      "
                    >
                      <td valign="center" style="width: 10%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h1") }}</span>
                        </p>
                      </td>
                      <td valign="center" style="width: 40%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h2") }}</span>
                        </p>
                      </td>
                      <td valign="center" style="width: 11%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span>
                            {{ $t("exhibitionTable.a3") }}
                          </span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.c1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span v-html="$t('exhibitionTable.c2')"> </span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span v-html="$t('exhibitionTable.c3')"></span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.c4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d4") }}</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP3") }}
              </div>
              <p class="m-b-14">{{ $t("bambooExhibitionP4") }}</p>
              <div class="pc" style="width: 699px; margin: 0 auto">
                <el-image style="width: 100%" :src="cImg1" :fit="'fill'">
                </el-image>
              </div>
              <div class="h5">
                <el-image style="width: 100%" :src="cImg1" :fit="'fill'">
                </el-image>
              </div>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP5") }}
              </div>
              <p class="m-b-14">{{ $t("bambooExhibitionP6") }}</p>
              <p class="m-b-14">{{ $t("bambooExhibitionP7") }}</p>
              <div class="p-indent">
                <p class="flex a-c m-b-14">{{ $t("bambooExhibitionP8") }}</p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP9") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP10") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP11") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP12") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP13") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP14") }}
                </p>
              </div>
              <div class="m-b-34">
                {{ $t("bambooExhibitionP15") }}
              </div>
              <div class="pc" style="width: 699px; margin: 0 auto">
                <el-image style="width: 100%" :src="cImg2" :fit="'fill'">
                </el-image>
              </div>
              <div class="h5">
                <el-image style="width: 100%" :src="cImg2" :fit="'fill'">
                </el-image>
              </div>

              <p class="m-t-60 m-b-20">
                {{ $t("bambooExhibitionP16") }}
              </p>
              <p class="m-b-20">{{ $t("bambooExhibitionP17") }}</p>
              <p>{{ $t("bambooExhibitionP18") }}</p>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP19") }}
              </div>
              <!-- <p>请于2022年8月30日前，<span class="a-color">发送参展回执</span> 至大会展览组李婷女士 （ <span
                                    class="g-color">tli@inbar.int</span> ）。</p> -->
              <p v-html="$t('bambooExhibitionP20')" @click="openUrl"></p>
            </div>

            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP21") }}
              </div>
              <!-- <p class="flex a-c m-b-14"> <span class="dot"></span> 国际竹藤组织东道国部主任：傅金和博士</p> -->
              <p class="flex a-c m-b-14" v-html="$t('bambooExhibitionP22')"></p>
              <p class="m-b-14 text-indent-20 pc">
                <span v-html="$t('bambooExhibitionP23')"></span>
                <span v-html="$t('bambooExhibitionP24')"></span>
              </p>
              <p
                class="m-b-14 h5 text-indent-20"
                v-html="$t('bambooExhibitionP23')"
              ></p>
              <p
                class="m-b-14 h5 text-indent-20"
                v-html="$t('bambooExhibitionP24')"
              ></p>
              <p class="flex m-b-14">
                <span class="dot m-t-8"></span>
                <span v-html="$t('bambooExhibitionP25')"></span>
              </p>
              <p class="text-indent-20 pc">
                <span v-html="$t('bambooExhibitionP26')"></span>
                <span v-html="$t('bambooExhibitionP27')"></span>
                <span></span>
              </p>
              <p
                class="h5 text-indent-20 m-b-14"
                v-html="$t('bambooExhibitionP26')"
              ></p>
              <p
                class="h5 text-indent-20"
                v-html="$t('bambooExhibitionP27')"
              ></p>
            </div>
          </div>
          <!-- <div class="onew1" v-if="type == 1">
            <div class="name">{{ $t("exh").zuzi.title }}</div>
            <div class="imgList">
              <img
                v-for="(item, index) in $t('exh').zuzi.img"
                :key="index"
                :src="item"
                alt=""
              />
            </div>
          </div> -->
          <div class="two" v-if="type == 0">
            <div
              class="name"
              v-if="$t('exh').list[iasd] && $t('exh').list[iasd].gs.length > 1"
              @click="setType1(0)"
            >
              {{
                $t("exh").list[iasd].title2
                  ? $t("exh").list[iasd].title2
                  : $t("exh").list[iasd].title1
                  ? $t("exh").list[iasd].title1
                  : $t("exh").list[iasd].title
              }}
            </div>
            <div
              class="navList"
              :style="
                $t('exh').list[iasd].gs.length > 3
                  ? ''
                  : 'justify-content: center;'
              "
            >
              <div style="width: 100%; display: flex; justify-content: center">

                <div
                  class="item"
                  :class="number == 0 ? 'select' : ''"
                  @click="setType(0)"
                  v-if="iasd == 27"
                ><span v-if="!lang" style='opacity: 0;' class='yidong'>C</span>
                  {{
                    lang
                      ? "洪雅竹元科技有限公司"
                      : "Hongya Zhuyuan Technology Co., Ltd."
                  }}
                  <span v-if="!lang" style='opacity: 0;' class='yidong'>C</span>
                </div>
              </div>
              <div
                class="item"
                :class="number == index ? 'select' : ''"
                :style="
                  !_isMobile() && item.padding
                    ? 'padding: 8px 20px;white-space: nowrap;'
                    : ''
                "
                v-for="(item, index) in $t('exh').list[iasd].gs"
                @click="setType(index)"
                :key="index"
                v-if="
                  ($t('exh').list[iasd].title1 && index != 0 && !item.row) ||
                  (!$t('exh').list[iasd].title1 && !item.row)
                "
              >
                <span v-html="item.title"></span>
              </div>
              <!-- <div
                class="item"
                :class="number == 4 ? 'select' : ''"
                :style="_isMobile() ? 'height: 110px;' : ''"
                @click="setType(4)"
                v-if="iasd == 27"
              >
                <p>
                  {{
                    lang
                      ? "江苏见竹绿建竹材科技股份有限公司"
                      : "Jiangsu See Bamboo Green Material Technology Co., Ltd."
                  }}
                </p>
                <p>
                  {{
                    lang
                      ? "赣州森泰竹木有限公司"
                      : "Ganzhou Sentai Bamboo & Wood Co., Ltd."
                  }}
                </p>
              </div> -->
            </div>
            <div class="imgList">
              <div v-if="$t('exh').list[iasd].gs[number]">
                <video
                  src="../../assets/images/exhibition/1.mp4"
                  controls
                  v-if="$t('exh').list[iasd].gs[number].video == 1"
                ></video>
                <video
                  src="../../assets/images/exhibition/2.mp4"
                  controls
                  v-if="$t('exh').list[iasd].gs[number].video == 2"
                ></video>
              </div>
              <viewer
                :images="$t('exh').list[iasd].gs[number].img"
                v-for="(item, index) in $t('exh').list[iasd].gs[number].img"
                :key="index"
              >
                <img :src="item" alt="" />
              </viewer>
              <!-- <img
                :src="item"
                alt=""
                v-for="(item, index) in $t('exh').list[iasd].gs[number].img"
                :key="index"
              /> -->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableImg: null,
      cImg1: null,
      cImg2: null,
      number: 0,
      type: 0,
      iasd: 0,
      fits: ["fill", "contain", "cover", "none", "scale-down"],
    };
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  watch: {
    $route(to, from) {
      console.log(this.$route.query.index);
      if (to.path == "/exhibitionDetails") {
        console.log(this.$route.query.type);
        this.type = this.$route.query.type;
        this.iasd = this.$route.query.i;
        this.number = 0;
        sessionStorage.removeItem("number");
        this.$forceUpdate();
      }
    },
  },
  created() {},
  mounted() {
    this.type = this.$route.query.type;
    this.iasd = this.$route.query.i;
    if (sessionStorage.getItem("old") == this.iasd) {
      if (sessionStorage.getItem("number")) {
        this.number = sessionStorage.getItem("number");
      }
    } else {
      sessionStorage.setItem("old", this.iasd);
      this.number = 0;
    }
  },
  methods: {
    setType1(i) {
      if (this.$t("exh").list[this.iasd].title1) {
        sessionStorage.setItem("number", i);
        this.number = i;
        this.$forceUpdate();
      }
    },
    setType(i) {
      sessionStorage.setItem("number", i);
      this.number = i;
      this.$forceUpdate();
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="stylus">
video {
  max-width: 100%;
}

.exhibition {
  .pageBox {
    .header {
      .el-image {
        display: block;
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .exhibition {
    background: #fafafa;
    width: 100%;
    overflow: hidden;

    table, td, th {
      border: 0.5px solid #f2f2f2;
    }

    p, div {
      line-height: 1;
    }

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 60px;
        }
      }

      .container {
        width: 100%;
        max-width: 1520px;
        margin: auto;
        padding: 26px 60px 97px 60px;
        text-align: left;
        box-sizing: border-box;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          margin-top: 30px;
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 24px;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 28px;
            color: #405E3C;
            margin: 43px 0 29px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }

        .tab-box {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  .exhTable {
    width: 100%;

    td {
      height: 30px;
    }

    tr {
      height: 60px;
    }
  }

  .two {
    max-width: 1400px;
    margin: auto;
  }

  .two .name {
    padding: 30px 40px 0;
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    color: #405e3c;
    cursor: pointer;
  }

  .two .navList {
    margin: auto;
    max-width: 1400px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  .two .navList .item {
    background-color: #deeec9;
    border: 1px solid #8dc63f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 20px;
    cursor: pointer;
    padding: 20px;
    box-sizing: border-box;
    line-height: 1.3 !important;
  }

  .two .navList .select {
    background-color: #8dc63f;
  }

  .two .imgList img {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }

  .onew1 {
    max-width: 1400px;
    margin: auto;
  }

  .onew1 .name {
    width: 100%;
    text-align: center;
    padding: 60px 0;
    font-weight: 700;
    font-size: 32px;
    color: #405e3c;
  }

  .onew1 .imgList img {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }

  .newPages {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 1400px;
    padding: 60px 0 40px;
    box-sizing: border-box;
    margin: auto;
  }

  .newPages .item {
    width: 200px;
    height: 70px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #405e3c;
    background-image: url('../../assets/images/exhibition/itemback.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .newPages .item:hover {
    background-image: url('../../assets/images/exhibition/hv.png');
  }
}

@media screen and (max-width: 728px) {
  ::v-deep .yidong {
    display: none !important;
  }

  .exhibition {
    width: 100%;
    overflow: hidden;

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          width: 164px;
          height: 60px;
          line-height: 60px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 22px;
        }
      }

      .container {
        padding: 20px;
        text-align: left;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          margin-top: 30px;
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 24px;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 20px;
            color: #405E3C;
            // margin: 43px 0 29px 0;
            margin: 15px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }

        .tab-box {
          font-size: 12px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    .exhTable {
      width: 100%;
    }
  }

  .two {
    width: 100%;
  }

  .two .name {
    padding: 20px 10px 0;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: #405e3c;
  }

  .two .navList {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-x: auto;
    white-space: nowrap;
  }

  /* 滚动条样式 */
  .two .navList::-webkit-scrollbar {
    cursor: pointer;
    height: 5px;
    width: 5px;
  }

  .two .navList::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #405e3c;
  }

  .two .navList .item {
    height: 60px;
    min-width: 120px;
    background-color: #deeec9;
    border: 1px solid #8dc63f;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size: 12px;
    cursor: pointer;
    padding: 0 5px;
    line-height: 1;
    box-sizing: border-box;
    white-space: break-spaces;
  }

  .two .navList .select {
    background-color: #8dc63f;
  }

  .two .imgList video {
    width: 100%;
  }

  .two .imgList img {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }

  .onew1 {
    max-width: 1400px;
    margin: auto;
  }

  .onew1 .name {
    width: 100%;
    text-align: center;
    padding: 20px 0;
    font-weight: 700;
    font-size: 20px;
    color: #405e3c;
  }

  .onew1 .imgList img {
    display: block;
    margin-bottom: 20px;
    width: 100%;
  }

  .newPages {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 1400px;
    padding: 60px 0 40px;
    box-sizing: border-box;
    margin: auto;
  }

  .newPages .item {
    width: 200px;
    height: 70px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ffffff;
    background-color: #405e3c;
    background-image: url('../../assets/images/exhibition/itemback.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .newPages .item:hover {
    background-image: url('../../assets/images/exhibition/hv.png');
  }
}
</style>